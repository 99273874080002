import React from 'react';
import tw from 'twin.macro';
import { login, isAuthenticated } from '../utils/auth';
import Layout from '../components/UI/Layout';
import Card from '../components/UI/Card';
import { getProfile } from '../utils/auth';
import { useStaticQuery, graphql, Link } from 'gatsby';
import RightArrow from '../components/Icons/RightArrow';

const Home = () => {
  const {
    sampleQueries: { nodes: featuredSampleQueries },
    tutorials: { nodes: featuredTutorials },
  } = useStaticQuery(query);

  if (!isAuthenticated()) {
    login();
    return <p>Redirecting to login...</p>;
  }

  const user = getProfile();

  return (
    <Layout>
      <div css={[tw`m-4 md:m-8`]}>
        <Card>
          <div css={[tw`flex`]}>
            <div>
              <p css={[tw`font-black`]}>Hi {user.name}!</p>
              <div css={['height: 3px;', tw`bg-indigo-500`]}></div>
            </div>
            <div></div>
          </div>
          <p css={[tw`mt-3 text-sm font-thin text-gray-600`]}>
            This is a fancy blurb of text to talk about something neat. There
            will soon be a lot of neat things, but first we must get a basic
            understanding on how the frontend will look. I am just going to copy
            and paste this to make things go quicker. This is a fancy blurb of
            text to talk about something neat. There will soon be a lot of neat
            things, but first we must get a basic understanding on how the
            frontend will look. I am just going to copy and paste this to make
            things go quicker.
          </p>
          <p css={[tw`mt-6`]}>Let's talk about something else</p>
          <p css={[tw`mt-3 text-sm font-thin text-gray-600`]}>
            This is a fancy blurb of text to talk about something neat. There
            will soon be a lot of neat things, but first we must get a basic
            understanding on how the frontend will look.
          </p>
        </Card>
        <div
          css={[
            tw`flex-col flex space-y-8 md:space-y-0 md:flex-row mt-8 md:space-x-8`,
          ]}
        >
          <Card>
            <div css={[tw`flex`]}>
              <div>
                <p css={[tw`font-black`]}>Featured Queries</p>
                <div css={['height: 3px;', tw`bg-indigo-500`]}></div>
              </div>
              <div></div>
            </div>
            <div
              css={[
                tw`mt-3 text-base font-thin text-gray-600 space-y-2`,
                'min-width: 250px;',
              ]}
            >
              {featuredSampleQueries.map((query, index) => {
                return (
                  <div key={index}>
                    <Link to={query.sampleQueryPath} css={[tw``]}>
                      <div css={[tw`flex justify-between`]}>
                        {query.Title}
                        <RightArrow css={[tw`h-5 w-5 self-center`]} />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </Card>
          <Card>
            <div css={[tw`flex`]}>
              <div>
                <p css={[tw`font-black`]}>Featured Tutorials</p>
                <div css={['height: 3px;', tw`bg-indigo-500`]}></div>
              </div>
              <div></div>
            </div>
            <div
              css={[
                tw`mt-3 text-base font-thin text-gray-600 space-y-2`,
                'min-width: 250px;',
              ]}
            >
              {featuredTutorials.map((tutorial, index) => {
                return (
                  <div key={index}>
                    <Link to={tutorial.tutorialPath} css={[tw``]}>
                      <div css={[tw`flex justify-between`]}>
                        {tutorial.Title}
                        <RightArrow css={[tw`h-5 w-5 self-center`]} />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </Card>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

const query = graphql`
  {
    sampleQueries: allStrapiSampleQueries(
      filter: { IsFeatured: { eq: true } }
    ) {
      nodes {
        Title
        sampleQueryPath: gatsbyPath(
          filePath: "/sample-queries/{strapiSampleQueries.Slug}"
        )
      }
    }
    tutorials: allStrapiTutorials(filter: { IsFeatured: { eq: true } }) {
      nodes {
        Title
        tutorialPath: gatsbyPath(filePath: "/tutorials/{strapiTutorials.Slug}")
      }
    }
  }
`;
